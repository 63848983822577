@import "variables";
@import "./icons/style";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font-family--base;
  font-size: $font-size--base;
  font-weight: $font-weight--normal;
  background-color: $slate;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $light-slate;
}

h1,
h2,
h3 {
  font-weight: $font-weight--light;
}
