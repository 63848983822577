.report-toolbar-legacy {
  &__grid {
    display: flex;
    align-items: center;
    margin: 0;
  }
  &__buttons {
    margin-left: 8px;
    margin-right: 8px;
  }
  .report-toolbar__generate-report-button {
    white-space: nowrap;
  }
  .nui-grid_col.nui-grid_col--1-of-1 {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0;
  }
}
