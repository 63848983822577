.file-upload-modal {
  &__content {
    border: 1px dashed lightgrey;
    padding: 32px;
    text-align: center;
    margin: 16px;
    border-radius: 8px;
  }
  &__input {
    display: none;
  }
  .nui-modal_content {
    height: 190px;
    .nui-modal_children {
      position: relative;
      padding: 0;
      height: 100%;
    }
  }
}
