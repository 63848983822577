@import "node_modules/@q4/nimbus-ui/dist/variables";

.toggleButton {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 170px;
  height: 32px;
  margin: 0px 4px;
}

.toggleButton input {
  display: none;
}

.toggleButton .labels {
  width: 100%;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.toggleButton .labels::after {
  content: attr(data-text);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: $white;
  background-color: $black-smoke;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
}

.toggleButton .labels::before {
  content: attr(data-text);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: $black-smoke;
  background-color: $citrus;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
}

.toggleButton input:checked ~ .labels::after {
  opacity: 0;
}

.toggleButton input:checked ~ .labels::before {
  opacity: 1;
}
