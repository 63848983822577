.chip-date-picker {
  padding-left: 0;
  .nui-textbox {
    height: 32px;
  }
  .nui-textbox.nui-day-picker_textbox {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .nui-day-picker_indicator--calendar {
    top: 8px;
  }
  .nui-chip_icon.ni-lock-4pt::before {
    opacity: 0.5;
  }
}

.nui-day-picker.chip-date-picker__date-picker {
  max-width: 100%;
}
