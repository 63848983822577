@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/ag-grid";

@include app-namespace-wrap() {
  .fund {
    height: 100%;
    padding: 0;
    overflow-y: hidden;
    // Slate grey header styles
    .nui-banner_details {
      color: #939ba0;
    }
    .nui-grid {
      margin: 0;
    }
    .nui-toolbar_inner {
      padding: 0;
    }
    &__grid {
      width: 100%;
      height: 100%;
      margin: 0;
      > div {
        padding: 0;
      }
    }
    &__transactions > .nui-toolbar {
      border-right: 1px solid #3f87ca;
    }
    &__transactions {
      > .nui-layout {
        border-right: 1px solid #3f87ca;
      }
    }
    .nui-grid_col,
    .nui-layout {
      height: 100%;
    }
    .nui-layout {
      padding: 0;
    }
    .nui-grid_col--1-of-2 {
      .manager-toolbar__left-column {
        padding-left: 32px;
      }
    }
    // No data message styles
    &__no-data-message {
      text-align: center;
      top: 50%;
      position: relative;
      margin: 0px;
      transform: translateY(-80px);
    }
  }
}
