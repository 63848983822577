.alert-msg-container {
  display: flex;
  align-items: center;

  .icon {
    color: #f1af0f;
    margin-right: 4px;
  }
}

.check-option {
  display: flex;
  flex-direction: row;

  > span {
    margin-left: 4px;
  }
}

.error {
  color: red;
}
