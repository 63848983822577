@import "src/styles/variables";

.analysis-modal {
  .nui-modal_header {
    background: #2a3035;
  }

  .nui-modal_children {
    padding: 0px;
  }

  .subTitle {
    background-color: $q4-blue;
    margin: 0px;
    padding: 10px;
    color: white;
    font-weight: 400;
  }
}
