@import "../../../../styles/mixins";
@import "../../../../styles/variables";
.svl-search {
  width: 100%;
  height: 100%;
  .nui-search {
    width: 100%;
    height: 64px;
    margin-right: 8px;
    .nui-search_inner {
      padding: 0 16px;
      height: 64px;
    }
  }
  .nui-search_input {
    text-indent: 18px;
    font-size: 20px;
  }
  .nui-search_icon {
    font-size: 22px;
  }
  &_list {
    background-color: white;
    width: 50vw;
    max-height: 80vh;
    &--loading {
      .nui-selection-list_option,
      .nui-spinner {
        min-height: gux(10);
      }
      .nui-selection-list_option {
        &:hover {
          // * !important is overriding nimbus inline style code
          background-color: transparent !important;
        }
      }
      .nui-selection-list--light .nui-selection-list_option {
        background-color: transparent;
        border-color: transparent;
      }
    }
    &--popover {
      .nui-scrollbars {
        height: 80vh;
        max-height: 80vh;
        box-shadow: 4px 4px 4px #0003;
      }
      .simplebar-content {
        max-height: 80vh;
      }
    }
    p {
      margin: 0;
    }
    .nui-selection-list_option {
      padding: 20px 10px 20px 10px;
      h2 {
        font-size: 1.2em;
      }
    }
  }
  .nui-selection-list_title {
    white-space: nowrap;
  }
  .nui-scrollbars {
    height: 250px;
  }
  .nui-selection-list_description {
    padding: 0px;
    margin: 0px;
  }
}
