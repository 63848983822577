.add-funds-form {
  &__input {
    display: flex;
    flex-flow: column;
    margin-bottom: 16px;
    font-family: "Open Sans", Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
  }
  &__label,
  &__textbox {
    margin-bottom: 4px;
  }
  &__error {
    color: #ec6a4c;
  }
}
