@import "../../styles/svl-base-view";

@include app-namespace-wrap() {
  .home {
    @include svl-base-view;
    .nui-grid.grid-toolbar__grid {
      > div {
        &:first-child {
          padding-left: 32px;
        }
        &:last-child {
          padding-right: 32px;
        }
      }
    }
    &__summary-enhancements {
      .nui-grid_col.nui-grid_col--2-of-5,
      .nui-grid_col.nui-grid_col--3-of-5 {
        position: relative;
        margin: 0;
      }
      .nui-grid.home__left-grid {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #e3e3e3;
        border-right: 1px solid #3f87ca;
        > .nui-grid_col.nui-grid_col--1-of-1 {
          padding: 0;
          .surveillance-grid {
            height: calc(100% - 64px) !important;
            min-height: calc(100% - 64px) !important;
            .ag-root-wrapper {
              height: 100% !important;
            }
            .dev-loader {
              [class^="loader-"] {
                margin-top: 0px;
              }
            }
            &.top-net-surveillance-grid-buyers {
              width: 49.9%;
              float: left;
              .ag-overlay-wrapper {
                padding-top: 48px;
              }
            }
            &.top-net-surveillance-grid-sellers {
              width: 49.9%;
              float: right;
              .ag-overlay-wrapper {
                padding-top: 48px;
              }
            }
            &.top-dtc-increase-surveillance-grid {
              width: 49.9%;
              float: left;
              .ag-overlay-wrapper {
                padding-top: 48px;
              }
            }
            &.top-dtc-decrease-surveillance-grid {
              width: 49.9%;
              float: right;
              .ag-overlay-wrapper {
                padding-top: 48px;
              }
            }
          }
        }
      }
      .nui-grid_col.nui-grid_col--2-of-5 {
        .nui-grid_col.grid-toolbar__right-column {
          padding: 0;
        }
      }
      .nui-select_filter {
        display: flex;
        font-size: 13px;
        > * {
          margin-right: 8px;
        }
        .nui-textbox {
          max-width: 64px;
        }
        label {
          display: flex;
          align-items: center;
          > div {
            padding-right: 4px;
          }
        }
        span {
          line-height: 40px;
        }
      }
    }
  }
}

.home-banner-buttons-container {
  padding-top: 5px;
}

.home-institution-filters {
  .nui-popover_inner {
    background-color: #0f5ca3;
  }
  button {
    width: 100%;
  }
}
