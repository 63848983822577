@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/ag-grid";

@mixin svl-base-view {
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  height: 100%;
  padding: 0;
  overflow-y: hidden;
  // Slate grey header styles
  .nui-banner_details {
    color: #939ba0;
    font-size: 13px;
  }
  .nui-grid {
    display: flex;
    width: 100%;
    margin: 0;
  }
  .nui-toolbar_inner {
    padding: 0;
  }
  &__grid {
    width: 100%;
    // 100vh height minus 180px, which is the height of the blue search bar plus the grey page banner
    height: calc(
      100vh - 180px
    ); // ! This dynamic height stuff still effects the dev loader, but is not needed for the grid anymore
    margin: 0;
    > div {
      padding: 0;
    }
    section.nui-toolbar.grid-toolbar.nui-toolbar--q4-blue {
      border-right: 1px solid #3f87ca;
    }
  }
  &__grid-toolbar-title {
    margin-right: 32px;
  }
  .grid-toolbar__left-column {
    > div {
      display: flex;
      align-items: center;
    }
  }
  .nui-grid_col--1-of-1 {
    margin: 0;
    > .nui-layout {
      border-right: 1px solid #3f87ca;
    }
  }
  .nui-day-picker {
    margin-right: 8px;
    .nui-textbox_input {
      font-size: 13px;
    }
  }
  .nui-grid_col--1-of-1:first-child {
    > .nui-layout {
      // 100% height minus 64px, which is the height of the blue bar
      height: calc(
        100% - 64px
      ); // ! This dynamic height stuff still effects the dev loader, but is not needed for the grid anymore
    }
  }
  .nui-grid_col,
  .nui-layout {
    height: 100%;
  }
  .nui-layout {
    padding: 0;
  }
  .nui-button {
    white-space: nowrap;
    margin-left: 0px;
    margin-right: 8px;
  }
}
