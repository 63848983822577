@import "src/styles/svl-base-view";

@include app-namespace-wrap() {
  .banklist {
    @include svl-base-view;
    .nui-grid_col.grid-toolbar__right-column.nui-grid_col--1-of-2:last-child {
      display: flex;
      align-items: center;
      .nui-button {
        margin-left: 8px;
      }
    }
  }
}
