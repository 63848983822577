.nui-grid.chip-date-picker-list {
  position: relative;
  display: flex;
  flex-flow: column;
  padding-right: 36px;
  .nui-grid_col.nui-grid_col--1-of-1 {
    display: flex;
    width: 100%;
    padding: 0px;
    &:first-child {
      margin-bottom: 4px;
    }
    > .nui-grid {
      .nui-grid_col {
        padding: 0;
        padding-left: 4px;
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  .nui-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
