@import "node_modules/@q4/nimbus-ui/dist/variables";

$app-namespace: "q4-platform";

// Colors
$surveillance-blue-light: #4696e0;
$surveillance-blue-dark: #297ac3;
$surveillance-white: white;
$surveillance-grey-fa: #fafafa;
$surveillance-grey-eee: #eeeeee;
$surveillance-grey-ddd: #dddddd;
$surveillance-black: black;

// Colors borrowed from Nimbus UI
// !These variables can be removed once the ProgressBar gets migrated to the Nimbus UI repository
$citrus: #f1af0f;
$rain: #297ac3;
