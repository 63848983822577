.react-numeric-input {
  width: 100%;
  height: 40px;
  background: #1f6bb0;
  border-radius: 3px;
  font-size: 12px;
  input {
    width: 88%;
    height: 40px;
    background: #1f6bb0;
    border-radius: 3px;
    color: white;
    // ! important is being used to override inline styles
    border: 0 !important;
    padding: 0px !important;
    text-indent: 8px;
    &::placeholder {
      color: white;
    }
    &:focus {
      outline: 0;
    }
  }
  b {
    // ! important is being used to override inline styles
    width: 12% !important;
    height: 50%;
    background: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
    border-radius: 0 !important;
    top: 0 !important;
    right: 0 !important;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      // ! important is being used to override inline styles
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      border: 0 !important;
      border-color: transparent !important;
      cursor: pointer;
      &:before,
      &:after {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-style: solid;
        content: "";
        border-width: 0 4px 6.5px 4px;
      }
      &:before {
        border-color: transparent transparent #ffffff transparent;
      }
      &:after {
        border-color: transparent transparent #1f6bb0 transparent;
        transform: translate(0, 2px);
      }
    }
  }
  b ~ b {
    top: 50% !important;
    i {
      &:before,
      &:after {
        border-width: 6.5px 4px 0 4px;
      }
      &:before {
        border-color: #ffffff transparent transparent transparent;
      }
      &:after {
        border-color: #1f6bb0 transparent transparent transparent;
        transform: translate(0, -2px);
      }
    }
  }
}
