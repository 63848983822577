.edit-managers-form {
  &__input {
    display: flex;
    flex-flow: column;
  }
  &__label,
  &__textbox {
    margin-bottom: 4px;
  }
  &__error {
    color: #ec6a4c;
  }
}
