.drag-and-drop {
  display: "inline-block";
  position: "relative";
  &__overlay {
    border: "dashed #939ba0 4px";
    background-color: "rgba(255,255,255,.8)";
    position: "absolute";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  &__overlay-message {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    text-align: center;
    align-items: center;
    color: #939ba0;
    font-size: 24px;
    background: rgba(255, 255, 255, 0.8);
    padding: 32px;
  }
}
