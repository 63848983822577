@import "node_modules/@q4/nimbus-ui/dist/mixins";
/**
 Used to provide app with an extra layer of CSS Hierarchy as to overpower any third party plugins that provide styling
 */
@mixin app-namespace-wrap($breakpoint: "") {
  @if ($breakpoint != "") {
    @at-root .#{$app-namespace}--#{$breakpoint} {
      @content;
    }
  } @else {
    @at-root .#{$app-namespace} {
      @content;
    }
  }
}
