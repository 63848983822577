@import "src/styles/variables";
@import "src/styles/mixins";

@include app-namespace-wrap() {
  display: flex;
  align-content: stretch;
  height: 100vh;
  overflow: hidden;

  &_navigation {
    flex-shrink: 0;
  }

  &_inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  &_view {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}
