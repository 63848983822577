.surveillance-grid-modal {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  outline: 0;

  .nui-modal_children {
    padding: 0px;
    height: 300px;
  }

  .nui-modal_container {
    width: 835px;
  }

  .nui-modal_content {
    position: relative;
  }

  .nui-grid {
    margin-left: 0px;
  }

  .nui-toolbar_inner {
    width: 100%;
    padding: 0px;

    h2 {
      font-size: 1em;
    }
  }

  .ag-root-wrapper.ag-layout-normal.ag-ltr {
    height: 100% !important; // !Override calculated height
  }
}

div#surveillance_grid_modal {
  width: 90vw;
  height: calc(90vh - 188px);

  .nui-modal_content {
    $height-of-header-and-footer: 112px;
    height: calc(100% - #{$height-of-header-and-footer});
  }
}

div#surveillance_grid_modalChildren {
  height: 100%;

  div#surveillance_grid_modal_literal {
    $height-of-grid-header: 65px;
    min-height: 0px;
  }
}

header.nui-modal_header {
  background: #0f5da3;
  padding: 12px 32px 12px 32px;
  h2,
  h3 {
    color: white;
  }
  .ni-close-4pt {
    &:before {
      color: white;
    }
  }
  .nui-modal_title-container,
  .nui-modal_language {
    width: 100%;
  }
}

section.nui-toolbar.nui-modal_footer {
  padding: 4px 32px;
}
