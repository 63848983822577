.filing-legend {
  display: flex;
  position: relative;
  align-items: center;
  font-family: "Open Sans", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: white;
  height: 8px;
  margin-right: 22px;
  text-indent: 12px;
  &:before {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 8px 8px 0 0;
  }
  &.yellow {
    &:before {
      border-color: yellow transparent transparent transparent;
    }
  }
  &.orange {
    &:before {
      border-color: orange transparent transparent transparent;
    }
  }
  &.red {
    &:before {
      border-color: red transparent transparent transparent;
    }
  }
}
