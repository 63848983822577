.grid-toolbar {
  &__left-column {
    font-family: "Open Sans", Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    font-size: 20px;
    line-height: 24px;
    padding-left: 32px;
  }
  &__left-column,
  &__right-column {
    margin: 0;
    > div {
      display: flex;
      flex-flow: row;
    }
  }
  &__grid {
    display: flex;
    align-items: center;
    width: 100%;
    .nui-grid_col--1-of-2:last-child {
      button {
        margin: 0;
        margin-left: 4px;
      }
    }
  }
  &__right-column {
    display: flex;
    justify-content: right;
    padding-right: 8px;
  }
  .nui-button {
    white-space: nowrap;
    min-width: 32px;
  }
  .nui-toolbar_inner {
    height: 64px;
    padding-bottom: 0;
  }
  .nui-toolbar_row {
    padding: 0;
  }
  .nui-banner_information {
    padding: 0;
  }
}
