.report-toolbar {
  width: 100%;
  &__grid {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0;
    width: 100%;
    .nui-grid_col {
      margin: 0;
    }
    li.nui-tabs_item {
      height: auto;
      padding: 8px;
    }
    .tier-1,
    .tier-2 {
      display: flex;
      flex-flow: row;
      align-items: center;
      padding: 0;
    }
    .tier-2 {
      border-top: 1px solid #1f6bb0;
      margin-top: 16px;
      padding-top: 16px;
      .nui-grid {
        width: 100%;
        margin: 0;
      }
    }
    form {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
    }
    form,
    label {
      display: flex;
      flex-flow: row;
    }
    label {
      margin-right: 8px;
    }
  }
  &__dates {
    width: 50%;
    > * {
      display: flex;
      flex-flow: row;
      margin-right: 8px;
    }
    button.nui-button {
      display: flex;
      align-items: center;
    }
  }
  &__dates-row {
    align-items: center;
    padding-bottom: 4px;
    &:last-child {
      padding-bottom: 0;
    }
    > * {
      margin-right: 4px;
    }
  }
  &__dates,
  &__form {
    padding: 0;
  }
  &__buttons {
    margin-left: 8px;
  }
  &__generate-report-button {
    margin-left: 8px;
  }
  &__radio-button-label {
    display: flex;
    align-items: center;
    margin-right: 8px;
    &:first-child {
      margin-bottom: 4px;
    }
  }
  &__radio-button {
    margin-right: 4px;
  }
}
