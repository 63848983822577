@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/ag-grid";
@include app-namespace-wrap() {
  .custodian {
    height: 100%;
    padding: 0;
    overflow-y: hidden;
    // Slate grey header styles
    .nui-banner_details {
      color: #939ba0;
    }
    .nui-grid {
      display: flex;
      // align-items: center;
      width: 100%;
      margin: 0;
    }
    .nui-toolbar_inner {
      padding: 0;
    }
    &__grid {
      width: 100%;
      height: 100%;
      margin: 0;
      > div {
        padding: 0;
      }
      section.nui-toolbar.grid-toolbar.nui-toolbar--q4-blue {
        border-right: 1px solid #3f87ca;
      }
    }
    .nui-grid_col--1-of-3 {
      > .nui-layout {
        border-right: 1px solid #3f87ca;
      }
      .custodian-toolbar {
        border-right: 1px solid #3f87ca;
      }
    }
    .nui-grid_col.nui-grid_col--2-of-3 {
      .nui-grid.custodian__grid {
        display: flex;
        flex-flow: column;
        height: calc(100% - 156px);
        .nui-grid_col.nui-grid_col--1-of-1 {
          height: 50%;
          margin: 0;
          .nui-layout--justify-center {
            justify-content: start; // Override centering on these Switch to GridRow?
          }
        }
      }
    }
    // # Ends here
    .nui-grid_col,
    .nui-layout {
      height: 100%;
    }
    .nui-layout {
      padding: 0;
    }
    .nui-button {
      white-space: nowrap;
      margin-right: 8px;
    }
    .surveillance-grid {
      height: calc(100% - 64px); // remove height of header
    }
    .surveillance-grid.half-height {
      min-height: 50%;
      .ag-root-wrapper {
        height: 100% !important;
      }
    }
  }
}
