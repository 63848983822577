@import "src/styles/variables";

.surveillance-grid {
  // GENERAL
  .ag-header-viewport {
    background: $surveillance-blue-light;
  }
  // Remove border around the whole AG grid table
  .ag-root-wrapper.ag-layout-normal.ag-ltr {
    border: none;
  }
  // Color the border around the checkbox blue
  .ag-checkbox-input-wrapper::after,
  .ag-checkbox-input-wrapper.ag-checked::after,
  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: $surveillance-blue-dark;
  }
  .ag-cell:not(:first-child) {
    // Stop the invisible checkbox from occupying physical space
    .ag-selection-checkbox.ag-invisible {
      display: none !important;
    }
  }
  // HEADER
  .ag-header-cell {
    &.surveillance-grid__header {
      text-transform: uppercase;
      font-weight: normal;
      color: $surveillance-white;
      background: $surveillance-blue-light;
      border-right: 1px solid $surveillance-blue-dark;
      span.ag-icon.ag-icon-menu {
        color: $surveillance-black;
      }
      // 8px padding, like the gutter around the search input
      padding-left: 8px;
      padding-right: 8px;

      &.oversized {
        background: #f1af0f;
      }
    }
    // Style ascending, descending and menu icons
    .ag-icon-asc,
    .ag-icon-desc,
    .ag-icon-menu {
      &:before {
        color: $surveillance-white;
      }
    }
  }
  // ROW
  .ag-row-odd {
    background: $surveillance-grey-fa;
  }
  .ag-row-selected {
    background: $surveillance-grey-eee;
  }
  .ag-row:hover {
    background: $surveillance-grey-eee;
  }
  // CELL
  .ag-cell {
    &.surveillance-grid__cell {
      padding-left: 8px;
      padding-right: 8px;
      border-right: 1px solid $surveillance-grey-ddd;
      border-top: 0;
      border-bottom: 0;
      font-size: 13px;
      // Style links displayed inside a cell
      a {
        color: $surveillance-blue-dark;
        text-decoration: none;
      }
    }
  }
}
