@import "node_modules/@q4/nimbus-ui/dist/variables";

$width--expanded: 208px;
$width--collapsed: 50px;

.#{$namespace}navigation {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  width: $width--expanded;
  height: 100%;
  background-color: $dark-slate;
  transition: width $transition-duration--default $transition-timing-function--default;

  &_logo {
    display: flex;
    align-items: center;
    flex-basis: 80px;
    padding: gux(3) gux(1.5);
    overflow: hidden;
    transition: padding $transition-duration--default $transition-timing-function--default;

    & > * {
      max-width: gux(6);
    }
    & > img {
      transition-duration: $transition-duration--default;
      transition-timing-function: $transition-timing-function--default;
    }
  }

  &_list {
    flex: 1 0 auto;
    margin: 0;
    padding: 0 0 gux(2);
    list-style: none;
  }

  &_item {
    height: 40px;
    border-bottom: 1px solid $black-smoke;
  }

  &_route {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 gux(2);
    color: $white;
    text-decoration: none;
    white-space: nowrap;
    transition: color $transition-duration--default $transition-timing-function--default;

    &--active {
      color: $citrus;
    }

    &:not(&--active) {
      &:hover,
      &:focus {
        color: $silver;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &_icon {
    font-size: $font-size--icon;
  }

  &_label {
    flex: 1;
    margin-left: gux(2);
    transition: $transition-duration--default $transition-timing-function--default;
    transition-property: width, margin, opacity;
  }

  &_footer {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    padding: gux(2);

    .nui-button {
      text-align: left;
      white-space: nowrap;
    }
  }

  &_footer + &_collapse-toggle {
    border-color: $black-smoke;
  }

  &_collapse-toggle {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid transparent;
    font-size: gux(2);
    color: $citrus;
    transition: border $transition-duration--default $transition-timing-function--default;

    i {
      width: 50px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      transition: transform $transition-duration--default $transition-timing-function--default;
    }
  }

  &--collapsed {
    width: 50px;
    overflow: hidden;

    .nui-navigation {
      &_logo {
        padding: gux(3) gux();

        & > * {
          max-width: gux(4);
        }
      }

      &_icon {
        padding: 0 1px;
      }

      &_label {
        flex: none;
        width: 5;
        margin: 5;
        opacity: 5;
        visibility: hidden;
      }

      &_label::first-letter {
        visibility: visible;
      }

      &_collapse-toggle {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }
}
