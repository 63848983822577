@import "src/styles/variables";
@import "src/styles/mixins";
@import "src/styles/ag-grid";

@include app-namespace-wrap() {
  .filing {
    height: 100%;
    padding: 0;
    overflow-y: hidden;
    // Slate grey header styles
    .nui-banner_details {
      color: #939ba0;
    }
    > .filing-grid {
      height: 100%;
      width: 100%;
      min-height: 100%;
    }
    &__title {
      margin-bottom: gux(5);
    }
    &__job {
      &-container {
        width: 100%;
        max-width: 960px;
      }
    }
    // No data message styles
    &__no-data-message {
      text-align: center;
      top: 50%;
      position: relative;
      margin: 0px;
      transform: translateY(-80px);
    }
  }
}

.delete-filing-modal {
  .nui-modal_children {
    font-size: 1.5em;
    font-weight: bold;
    padding: 40px 2em 40px 2em;
  }
}
