.ag-cell.banklist-cell {
  .ag-cell-value {
    cursor: pointer;
  }
  &--mapping {
    padding: 0;
    .ag-cell-value {
      width: 100%;
    }
    .nui-button {
      text-align: left;
      text-transform: capitalize;
      border-radius: 0;
      width: 100%;
      height: 40px;
      margin-right: 0px;
      color: #297ac3;
      background: transparent;
      &:hover {
        color: #297ac3;
        background: transparent;
      }
    }
  }
}
