.ticker-selector {
  position: relative;
  margin: 0 8px;
  width: 230px;
  .nui-select__value-container {
    padding: 0;
    .nui-select__input input {
      // ! This important tag is here to override inline styles within the nimbus repository
      padding-left: 8px !important;
    }
  }
  .nui-select__placeholder {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    text-indent: 8px;
  }
}
