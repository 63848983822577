@import "../../../../styles/mixins";
@import "../../../../styles/variables";

@keyframes nui-progress-bar-loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.nui-progress-bar {
  height: 20px;
  width: 100%;
  border-radius: 3px;
  background-color: $ghost;
  overflow: hidden;

  .nui-progress {
    height: 20px;
    width: 0px;
    border-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: $citrus;
  }
  &--citrus {
    .nui-progress {
      background-color: $citrus;
    }
  }
  &--rain {
    .nui-progress {
      background-color: $rain;
    }
  }
  .nui-progress.loading {
    animation: nui-progress-bar-loading 1s infinite;
  }
}
