@import "src/styles/variables";

.entity-banner {
  position: relative;
  &__manager-info {
    max-width: 90%;
    line-height: 16px;
    margin-bottom: 16px;
    /* Handle scrolling */
    overflow-x: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    tr {
      td {
        padding-right: 1em;
        vertical-align: top;
        table {
          td {
            padding-right: 0.5em;
            white-space: nowrap;
            font-weight: bold;
            text-align: right;
          }
          td:last-child {
            font-weight: normal;
            text-align: left;
          }
        }
      }
    }
  }
  .nui-badge {
    display: flex;
    align-items: center;
    height: 32px;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .nui-banner_inner {
    padding-bottom: 0;
  }
  .nui-banner_information {
    padding-top: 16px;
    padding-bottom: 28px;
    width: 100%;
  }
  .nui-grid {
    display: flex;
    padding-right: 5px;
  }
  .nui-grid_col {
    margin: 0;
    padding: 0;
    &:last-child {
      display: flex;
      justify-content: right;
      .nui-button {
        position: absolute;
        top: 16px;
        margin-right: 8px;
        color: $citrus;
      }
    }
  }

  a {
    color: $citrus;
  }
}
