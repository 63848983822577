@import "src/styles/svl-base-view";

@include app-namespace-wrap() {
  .mutual-fund {
    @include svl-base-view;
    span.nui-text.nui-field_label-text.nui-field_label-text--truncate.nui-text--light-slate {
      color: white;
      font-size: 12px;
    }
    .nui-form {
      width: 550px;
      .react-numeric-input {
        input {
          width: 100%;
        }
      }
      .nui-grid_col.nui-grid_col--1-of-3 {
        padding-left: 4px;
        margin-bottom: 0px;
        .nui-toggle-input-base {
          margin-top: 12px;
          .nui-toggle-input-base_label-text {
            font-size: 12px;
            color: white;
          }
        }
      }
    }
  }
}
