@import "src/styles/variables";

.surveillance-grid {
  height: 100%;
  width: 100%;
  min-height: 100%;
  .ag-header-cell-resize::after {
    background: none;
  }
  .ag-header-cell-label .ag-header-cell-text {
    white-space: pre-wrap !important;
  }
  .row-bold {
    font-weight: bold;
  }

  .row-green {
    border: 0.1em solid $lime;
  }

  .row-citrus {
    border: 0.1em solid $citrus;
  }
}
