@import "../../../../styles/mixins";
@import "../../../../styles/variables";

@include app-namespace-wrap() {
  .header {
    > .nui-toolbar_inner {
      padding-left: 8px;
      padding-right: 32px;
      > .nui-toolbar_row {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    // * Styles for the AG grid blue toolbar
    .header_toolbar-grid {
      margin: 0;
      width: 100%;
      > div {
        margin: 0;
        padding: 0;
        &:first-child,
        &:last-child {
          display: inline-flex;
          align-items: center;
        }
        &:last-child {
          justify-content: flex-end;
        }
      }
    }
    .nui-grid_col--1-of-2 {
      width: 50%;
      height: 64px;
    }
  }
}
