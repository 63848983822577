.filing-toolbar {
  outline: 0;
  &__grid {
    margin: 0;
    height: 32px;
    width: 100%;
    > div {
      margin: 0;
      padding: 0;
      &:first-child,
      &:last-child {
        display: inline-flex;
        align-items: center;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
    .nui-checkbox {
      margin-left: 24px;
    }
    .nui-toggle-input-base_label-text {
      color: white;
    }
    .nui-grid_col--1-of-2:first-child {
      white-space: nowrap;
    }
    .nui-select__control {
      height: 32px;
      min-height: 32px;
      .nui-select__indicator--dropdown {
        height: 32px;
      }
    }
  }
  .nui-toolbar_inner {
    padding: 0 8px;
  }
}
