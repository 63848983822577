.add-transactions-form {
  &__input {
    display: flex;
    flex-flow: column;
    margin-bottom: 16px;
    font-family: "Open Sans", Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
  }
  &__label,
  &__numericinput {
    margin-bottom: 4px;
  }
  &__error {
    color: #ec6a4c;
  }
  .nui-grid_col {
    margin: 0;
  }
  input {
    color: #939ba0 !important;
    background: #eee !important;
  }
  input::placeholder {
    color: #939ba0 !important;
  }
  i {
    background: #eee !important;
  }
}
