.svl-custodian-list {
  align-items: center;
  display: flex;
  flex-flow: row;
  .nui-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
  }
  .medium {
    max-height: 34px;
  }

  .thin {
    max-height: 18px;
  }
}

.svl-custodian-button {
  color: #f1af0f;
}
