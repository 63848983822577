@import "src/styles/variables";
@import "src/styles/mixins";

@include app-namespace-wrap() {
  .report {
    height: 100%;
    overflow-y: hidden;
    > div {
      height: 100%;
      width: 100%;
      min-height: 100%;
    }
  }
}
