@import "variables";
@import "mixins";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?ui4pcq") format("woff2"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ui4pcq") format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ui4pcq") format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ui4pcq##{$icomoon-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="q4i-"],
[class*=" q4i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.q4i-filter {
  &:before {
    content: icon-content($q4i-filter);
  }
}
.q4i-add-2pt {
  &:before {
    content: icon-content($q4i-add-2pt);
  }
}
.q4i-add-4pt {
  &:before {
    content: icon-content($q4i-add-4pt);
  }
}
.q4i-edit-2pt {
  &:before {
    content: icon-content($q4i-edit-2pt);
  }
}
.q4i-edit-4pt {
  &:before {
    content: icon-content($q4i-edit-4pt);
  }
}
.q4i-report-blank-2pt {
  &:before {
    content: icon-content($q4i-report-blank-2pt);
  }
}
.q4i-report-blank-4pt {
  &:before {
    content: icon-content($q4i-report-blank-4pt);
  }
}
.q4i-warning-2pt {
  &:before {
    content: icon-content($q4i-warning-2pt);
  }
}
.q4i-warning-4pt {
  &:before {
    content: icon-content($q4i-warning-4pt);
  }
}
.q4i-rate-2pt {
  &:before {
    content: icon-content($q4i-rate-2pt);
  }
}
.q4i-rate-4pt {
  &:before {
    content: icon-content($q4i-rate-4pt);
  }
}
.q4i-caret-sm-down-2pt {
  &:before {
    content: icon-content($q4i-caret-sm-down-2pt);
  }
}
.q4i-caret-sm-down-4pt {
  &:before {
    content: icon-content($q4i-caret-sm-down-4pt);
  }
}
.q4i-caret-sm-left-2pt {
  &:before {
    content: icon-content($q4i-caret-sm-left-2pt);
  }
}
.q4i-caret-sm-left-4pt {
  &:before {
    content: icon-content($q4i-caret-sm-left-4pt);
  }
}
.q4i-caret-sm-right-2pt {
  &:before {
    content: icon-content($q4i-caret-sm-right-2pt);
  }
}
.q4i-caret-sm-right-4pt {
  &:before {
    content: icon-content($q4i-caret-sm-right-4pt);
  }
}
.q4i-caret-sm-up-2pt {
  &:before {
    content: icon-content($q4i-caret-sm-up-2pt);
  }
}
.q4i-caret-sm-up-4pt {
  &:before {
    content: icon-content($q4i-caret-sm-up-4pt);
  }
}
.q4i-checkmark-4pt {
  &:before {
    content: icon-content($q4i-checkmark-4pt);
  }
}
.q4i-close-4pt {
  &:before {
    content: icon-content($q4i-close-4pt);
  }
}
.q4i-download-4pt {
  &:before {
    content: icon-content($q4i-download-4pt);
  }
}
.q4i-export-4pt {
  &:before {
    content: icon-content($q4i-export-4pt);
  }
}
.q4i-search-2pt {
  &:before {
    content: icon-content($q4i-search-2pt);
  }
}
.q4i-search-4pt {
  &:before {
    content: icon-content($q4i-search-4pt);
  }
}
.q4i-sync-or-fetch-2pt {
  &:before {
    content: icon-content($q4i-sync-or-fetch-2pt);
  }
}
.q4i-sync-or-fetch-4pt {
  &:before {
    content: icon-content($q4i-sync-or-fetch-4pt);
  }
}
.q4i-itinerary-2pt {
  &:before {
    content: icon-content($q4i-itinerary-2pt);
  }
}
.q4i-itinerary-4pt {
  &:before {
    content: icon-content($q4i-itinerary-4pt);
  }
}
.q4i-broadcasting-off-filled-4pt {
  &:before {
    content: icon-content($q4i-broadcasting-off-filled-4pt);
  }
}
.q4i-earnings-2pt {
  &:before {
    content: icon-content($q4i-earnings-2pt);
  }
}
.q4i-earnings-4pt {
  &:before {
    content: icon-content($q4i-earnings-4pt);
  }
}
.q4i-releases-2pt {
  &:before {
    content: icon-content($q4i-releases-2pt);
  }
}
.q4i-releases-4pt {
  &:before {
    content: icon-content($q4i-releases-4pt);
  }
}
.q4i-bookmark-2pt {
  &:before {
    content: icon-content($q4i-bookmark-2pt);
  }
}
.q4i-bookmark-4pt {
  &:before {
    content: icon-content($q4i-bookmark-4pt);
  }
}
.q4i-contact-2pt {
  &:before {
    content: icon-content($q4i-contact-2pt);
  }
}
.q4i-contact-4pt {
  &:before {
    content: icon-content($q4i-contact-4pt);
  }
}
.q4i-contact-list-2pt {
  &:before {
    content: icon-content($q4i-contact-list-2pt);
  }
}
.q4i-contact-list-4pt {
  &:before {
    content: icon-content($q4i-contact-list-4pt);
  }
}
.q4i-meeting-2pt {
  &:before {
    content: icon-content($q4i-meeting-2pt);
  }
}
.q4i-meeting-4pt {
  &:before {
    content: icon-content($q4i-meeting-4pt);
  }
}
.q4i-star-2pt {
  &:before {
    content: icon-content($q4i-star-2pt);
  }
}
.q4i-star-4pt {
  &:before {
    content: icon-content($q4i-star-4pt);
  }
}
.q4i-trashbin-4pt {
  &:before {
    content: icon-content($q4i-trashbin-4pt);
  }
}
.q4i-trashbin-2pt {
  &:before {
    content: icon-content($q4i-trashbin-2pt);
  }
}
.q4i-pdf-2pt {
  &:before {
    content: icon-content($q4i-pdf-2pt);
  }
}
.q4i-pdf-4pt {
  &:before {
    content: icon-content($q4i-pdf-4pt);
  }
}
.q4i-report-open-2pt {
  &:before {
    content: icon-content($q4i-report-open-2pt);
  }
}
.q4i-report-open-4pt {
  &:before {
    content: icon-content($q4i-report-open-4pt);
  }
}
.q4i-webcast-2pt {
  &:before {
    content: icon-content($q4i-webcast-2pt);
  }
}
.q4i-webcast-4pt {
  &:before {
    content: icon-content($q4i-webcast-4pt);
  }
}
.q4i-xls-2pt {
  &:before {
    content: icon-content($q4i-xls-2pt);
  }
}
.q4i-xls-4pt {
  &:before {
    content: icon-content($q4i-xls-4pt);
  }
}
.q4i-duplicate-4pt {
  &:before {
    content: icon-content($q4i-duplicate-4pt);
  }
}
.q4i-duplicate-2pt {
  &:before {
    content: icon-content($q4i-duplicate-2pt);
  }
}
.q4i-info-fill {
  &:before {
    content: icon-content($q4i-info-fill);
  }
}
.q4i-activity-calendar-2pt {
  &:before {
    content: icon-content($q4i-activity-calendar-2pt);
  }
}
.q4i-activity-calendar-4pt {
  &:before {
    content: icon-content($q4i-activity-calendar-4pt);
  }
}
.q4i-bookmark-2pt {
  &:before {
    content: icon-content($q4i-bookmark-2pt);
  }
}
.q4i-bookmark-4pt {
  &:before {
    content: icon-content($q4i-bookmark-4pt);
  }
}
.q4i-company-2pt {
  &:before {
    content: icon-content($q4i-company-2pt);
  }
}
.q4i-company-4pt {
  &:before {
    content: icon-content($q4i-company-4pt);
  }
}
.q4i-contact-2pt {
  &:before {
    content: icon-content($q4i-contact-2pt);
  }
}
.q4i-contact-4pt {
  &:before {
    content: icon-content($q4i-contact-4pt);
  }
}
.q4i-csm-2pt {
  &:before {
    content: icon-content($q4i-csm-2pt);
  }
}
.q4i-csm-4pt {
  &:before {
    content: icon-content($q4i-csm-4pt);
  }
}
.q4i-home-2pt {
  &:before {
    content: icon-content($q4i-home-2pt);
  }
}
.q4i-home-4pt {
  &:before {
    content: icon-content($q4i-home-4pt);
  }
}
.q4i-html-2pt {
  &:before {
    content: icon-content($q4i-html-2pt);
  }
}
.q4i-html-4pt {
  &:before {
    content: icon-content($q4i-html-4pt);
  }
}
.q4i-presentation-2pt {
  &:before {
    content: icon-content($q4i-presentation-2pt);
  }
}
.q4i-presentation-4pt {
  &:before {
    content: icon-content($q4i-presentation-4pt);
  }
}
.q4i-press-releases-2pt {
  &:before {
    content: icon-content($q4i-press-releases-2pt);
  }
}
.q4i-press-releases-4pt {
  &:before {
    content: icon-content($q4i-press-releases-4pt);
  }
}
.q4i-price-2pt {
  &:before {
    content: icon-content($q4i-price-2pt);
  }
}
.q4i-price-4pt {
  &:before {
    content: icon-content($q4i-price-4pt);
  }
}
.q4i-question-mark-2pt {
  &:before {
    content: icon-content($q4i-question-mark-2pt);
  }
}
.q4i-question-mark-4pt {
  &:before {
    content: icon-content($q4i-question-mark-4pt);
  }
}
.q4i-team-2pt {
  &:before {
    content: icon-content($q4i-team-2pt);
  }
}
.q4i-team-4pt {
  &:before {
    content: icon-content($q4i-team-4pt);
  }
}
.q4i-insider-2pt {
  &:before {
    content: icon-content($q4i-insider-2pt);
  }
}
.q4i-insider-4pt {
  &:before {
    content: icon-content($q4i-insider-4pt);
  }
}
.q4i-suitcase-cog-2pt {
  &:before {
    content: icon-content($q4i-suitcase-cog-2pt);
  }
}
.q4i-suitcase-cog-4pt {
  &:before {
    content: icon-content($q4i-suitcase-cog-4pt);
  }
}
