@import "src/styles/svl-base-view";

@include app-namespace-wrap() {
  .dtcc {
    @include svl-base-view;
    .nui-layout {
      justify-content: flex-start;
    }
    &.dtcc__stock-data-feature {
      $gutter: 16px;
      $grey-header: 100px;
      $blue-nav-bar: 80px;
      $table-header-height: 210px;
      $top-grid-height: $blue-nav-bar + $table-header-height; // 273px
      $bottom-grid-offset: $grey-header + $top-grid-height; // 373px
      div#dtcc__stock-data-grid {
        height: $table-header-height;
        min-height: $table-header-height;
      }
      .nui-grid.dtcc__grid.dtcc__top-grid {
        height: $top-grid-height;
        .nui-layout {
          background-color: #4796e0;
        }
      }
      .nui-grid.dtcc__grid.dtcc__bottom-grid {
        height: calc(100% - #{$bottom-grid-offset});
      }
      .ag-root-wrapper.ag-layout-normal.ag-ltr {
        height: 100% !important;
      }
      .ag-horizontal-left-spacer {
        display: none;
      }
    }

    &.dtcc__overview-grid-toolbar-days {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-right: 10px;

      .nui-select {
        min-width: 60px;
        width: 60px;
      }

      .nui-select__control {
        min-width: 60px;
      }
    }
  }
}
